import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { connect } from 'react-redux'
import PrivateRoute from "./PrivateRoute";
import Loader from "./assets/F4IA.gif"
import GridGallery2014 from "./components/grid-gallery/GridGallery2014";
import GridGallery2015 from "./components/grid-gallery/GridGallery2015";
import GridGallery2016 from "./components/grid-gallery/GridGallery2016";
import GridGallery2017 from "./components/grid-gallery/GridGallery2017";
import GridGallery2018 from "./components/grid-gallery/GridGallery2018";
import GridGallery2019 from "./components/grid-gallery/GridGallery2019";

import JobPost from "./admin-dashboard/jobPost/JobPost";
import FoundationDayEventPage from "./pages/FoundationDayEventPage";

const AluminiPage = lazy(() => import('./pages/AluminiPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const CommittePage = lazy(() => import('./pages/CommittePage'));
const DonationPage = lazy(() => import('./pages/DonationPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const Notfound = lazy(() => import('./components/error-404/Notfound'));
const Loginpage = lazy(() => import('./pages/Loginpage'));
const RegisterationPage = lazy(() => import('./pages/RegisterationPage'));
const PaymentForm = lazy(() => import('./components/donationForm/PaymentForm'));
const ForgetPassword = lazy(() => import('./pages/ForgetPassword'));
const NewsEvent = lazy(() => import('./pages/NewsEvent'));
const SearchTeamMates = lazy(() => import('./pages/SearchTeamMates'));
const PhotoGallery = lazy(() => import('./pages/PhotoGallery'));
const BlogNewsEvent = lazy(() => import('./pages/BlogNewsEvent'));
const Thankyou = lazy(() => import('./components/thankyou/Thankyou'));
const SetPassword = lazy(() => import('./SetPassword'));
const UpdateProfile = lazy(() => import('./admin-dashboard/updateProfile/UpdateProfile'));
const FollowUs = lazy(() => import('./admin-dashboard/social-media/FollowUs'));
const ResetPassword = lazy(() => import('./admin-dashboard/home/forgotPage/ResetPassword'));
const SearchMates = lazy(() => import('./admin-dashboard/searchTeamMates/SearchMates'));
const AdminNews = lazy(() => import('./admin-dashboard/updateProfile/admin-news-events/AdminNews'));

function App(props) {

  const isAuthenticated = props.user?.data?.length === 0 ? false : true;

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div> <img className="w-loader" src={Loader} alt="loading..." /></div>}>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/alumini" element={<AluminiPage />} />
            <Route path="/committe" element={<CommittePage />} />
            <Route path="/news-event" element={<NewsEvent />} />
            <Route path="/donation" element={<DonationPage />} />
            <Route path="/gallery" element={<PhotoGallery />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/registration" element={<RegisterationPage />} />
            <Route path="/search" element={<SearchTeamMates />} />
            <Route path="/login" element={<Loginpage />} />
            <Route path="/payment" element={<PaymentForm />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />
            <Route path="/job-details" element={<JobPost />} />
            <Route path="/eventday" element={<FoundationDayEventPage />} />


            <Route path="/follow" element={<FollowUs />} />
            <Route path="/event/:id" element={<BlogNewsEvent />} />
            <Route path="/set-password/:key/:login" element={<SetPassword />} />
            <Route path="/searchTeamMates" element={<SearchMates />} />
            <Route path="/admin-news-event" element={<AdminNews />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/event-2014" element={<GridGallery2014 />} />
            <Route path="/event-2015" element={<GridGallery2015 />} />
            <Route path="/event-2016" element={<GridGallery2016 />} />
            <Route path="/event-2017" element={<GridGallery2017 />} />
            <Route path="/event-2018" element={<GridGallery2018 />} />
            <Route path="/event-2019" element={<GridGallery2019 />} />



            <Route exact path="/dashboard" element={<PrivateRoute ><UpdateProfile /></PrivateRoute>} />
            <Route path="/reset-password" element={<PrivateRoute><ResetPassword /></PrivateRoute>} />
            <Route path="/news-lattert" element={<PrivateRoute><NewsEvent /></PrivateRoute>} />
            <Route path="*" element={<Notfound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(App)
