import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const TopHeader = (props) => {
  const { token } = props.user?.data;
  const user_nicename = localStorage.getItem("user_nicename");
  const navigate = useNavigate();
  const Logout = () => {
    props.dispatch({ type: "LOGOUT" });
    window.localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <>
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="top-link">
                <li className="nav-item">
                  <Link to="/registration" className="nav-link">
                    Membership Registration
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="/eventday" className="nav-link">
                    EventDay
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link to="/search" className="nav-link">
                    Search
                  </Link>
                </li>
                <li className="nav-item">
                  {token ? (
                    <a onClick={Logout} className="nav-link">
                      Logout
                    </a>
                  ) : (
                    <Link to="/login" className="nav-link">
                      Login
                    </Link>
                  )}

                </li>
                <li className="nav-item">
                  {token ? (
                    <Link to="/dashboard" className="nav-link">
                      Dashboard
                    </Link>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(TopHeader);
